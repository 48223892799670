<template>
  <div class="fifthThemeContainer">
    <lbrx-kiosk-header
      v-if="!popup"
      :themeTemplate="themeTemplate"
      :logo="themeTemplate.header.logo"
      :hasBorderRadius="themeTemplate.header.borderRadius"
      v-model="isDisabled"
      :primaryColor="themeTemplate.primaryColor"
      :secondaryColor="themeTemplate.secondaryColor"
      :text-color="themeTemplate.textColor"
      :header-background="themeTemplate.header.headerBackground"
    />
    <div v-if="!popup" class="titleContainer">
      <div class="title-class">
        {{ themeTemplate.allowCategory == 1 && !categoryChosen ? getCategoryTitle : $t('Kiosk.servicesTitle') }}
      </div>
    </div>
    <div class="mainContainer">
      <div v-if="themeTemplate.allowCategory == 1 && !categoryChosen" :class="{'right-class': $i18n.locale === 'ar'}" class="row">
        <div :class="{'col-4': !isMobile, 'col-12': isMobile}" v-for="(category, index) in categoriesPage" :id="'service'+category.id" :key="index">
          <div
            :class="{'right-class': $i18n.locale === 'ar'}"
            :style="{'background': category.checked ? themeTemplate.services.selectedServiceBackgroundColor : '', 'opacity': category.disabled ? '0.7':'1'}"
            :title="category.name"
            @mouseup="handleClickCategory(category)"
            class="singleService"
          >
            <div
              v-if="category.translations && JSON.parse(category.translations)[$i18n.locale] != null"
              :style="{'color': category.checked ? themeTemplate.services.selectedServiceTextColor : '', 'font-size': getFontSize(JSON.parse(category.translations)[$i18n.locale]), 'line-height': getFontSize(JSON.parse(category.translations)[$i18n.locale])}"
              class="labelContainer"
              :class="{'right-class': $i18n.locale === 'ar'}"
            >
              <LbrxIconSelector :iconName="category.icon" :iconColor="getServiceTextColor" />
              {{ JSON.parse(category.translations)[$i18n.locale] }}
            </div>
            <div
              v-else-if="category.name"
              :style="{'color': category.checked ? themeTemplate.services.selectedServiceTextColor : '', 'font-size': getFontSize(category.name), 'line-height': getFontSize(category.name)}"
              class="labelContainer"
            >
              <LbrxIconSelector :iconName="category.icon" :iconColor="getServiceTextColor" />
              {{ category.name }}
            </div>
          </div>
        </div>
      </div>
      <div v-else :class="{'right-class': $i18n.locale === 'ar'}" class="services-container">
        <div class="row">
          <div
            :class="{
              'col-6': !isMobile && (popup || servicesPage.length < 3),
              'col-4': !isMobile && !popup && servicesPage.length > 2,
              'col-12': isMobile
            }"
            v-for="(service, index) in servicesPage"
            :id="'service'+service.id"
            :key="index"
          >
            <div
              :class="{'right-class': $i18n.locale === 'ar'}"
              :style="{'background': service.checked ? themeTemplate.services.selectedServiceBackgroundColor : '', 'opacity': service.disabled ? '0.7':'1'}"
              :title="service.title"
              v-long-press:@click="() => handleLongPress(service)"
              @mouseup="handleClick(service)"
              @touchend="() => handleTouch(service)"
              class="singleService"
            >
              <div
                v-if="service.translations && JSON.parse(service.translations)[$i18n.locale] != null"
                :style="{'color': service.checked ? themeTemplate.services.selectedServiceTextColor : '', 'font-size': getFontSize(JSON.parse(service.translations)[$i18n.locale]), 'line-height': getFontSize(JSON.parse(service.translations)[$i18n.locale])}"
                class="labelContainer"
                :class="{'right-class': $i18n.locale === 'ar'}"
              >
                {{ JSON.parse(service.translations)[$i18n.locale] }}
                <span style="font-weight:600;margin-left:5px;margin-right:5px" v-if="service.checked && service.qte">(x{{ service.qte }})</span>
              </div>
              <div
                v-else-if="service.title"
                :style="{'color': service.checked ? themeTemplate.services.selectedServiceTextColor : '', 'font-size': getFontSize(service.title), 'line-height': getFontSize(service.title)}"
                class="labelContainer"
              >
                {{ service.title }}
                <span style="font-weight:600;margin-left:5px;margin-right:5px" v-if="service.checked && service.qte">(x{{ service.qte }})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <lbrx-services-paginator
      v-if="pagesCount > 1 && allowPagination"
      @next-page="nextPage"
      @prev-page="prevPage"
      @set-page="setPage"
      :theme-template="themeTemplate"
      v-model="currentPage"
      :pages-count="pagesCount"
    />
    <div v-else class="paginator-holder"></div>

    <span v-if="!popup && showNextButton">
      <div v-if="themeTemplate.allowCategory == 1" class="buttons-container">
        <div v-if="categoryChosen" class="row">
          <div :class="{'col-6': servicesPage.length < 3, 'col-4': servicesPage.length > 2}">
            <lbrx-button
              id="cancelButton"
              v-model="isDisabled"
              @clicked="$emit('cancel')"
              :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'"
              :label="$t('Kiosk.backButton')"
              :color="themeTemplate.services.mainButtonBackground"
              :background="'transparent'"
              :border="'1px solid ' + themeTemplate.services.mainButtonBackground"
              height="8vmin"
              width="100%"
              font-size="1.3"
            />
          </div>
          <div class="col-4" v-if="servicesPage.length >= 3 && showNextButton"></div>
          <div v-if="showNextButton" :class="{'col-6': servicesPage.length < 3, 'col-4': servicesPage.length > 2}">
            <lbrx-button
              id="validateButton"
              v-model="isDisabled"
              @clicked="$emit('validate')"
              :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'"
              :label="phoneNumberNotAllowed ? $t('Kiosk.printTicketButton') : $t('Kiosk.nextButton')"
              :color="themeTemplate.services.mainButtonTextColor"
              :background="themeTemplate.services.mainButtonBackground"
              height="8vmin"
              width="100%"
              font-size="1.3"
            />
          </div>
        </div>
      </div>
      <div v-else class="nextButtonContainer" :style="{'margin-bottom': popup ? '8.5vmin': '2vmin'}">
        <lbrx-button
          id="validateButton"
          v-model="isDisabled"
          @clicked="$emit('validate')"
          :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'"
          :label="phoneNumberNotAllowed ? $t('Kiosk.printTicketButton') : $t('Kiosk.nextButton')"
          :color="themeTemplate.services.mainButtonTextColor"
          :background="themeTemplate.services.mainButtonBackground"
          height="8vmin"
          width="31%"
          font-size="1.3"
        />
      </div>
    </span>
    <div v-if="!popup" class="footer-container">
      <the-footer :color="themeTemplate.textColor" v-model="onLine" :background="themeTemplate.footer.footerBackground" />
    </div>
  </div>
</template>

<script>
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxKioskHeader from "@/components/LbrxKioskHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import LbrxServicesPaginator from "@/components/LbrxServicesPaginator.vue";
import longPressDirective from '../_helpers/longPressDirective';
import LbrxIconSelector from "@/components/LbrxIconSelector.vue";

export default {
  name: "LbrxKioskSixthTheme",
  components: { LbrxServicesPaginator, TheFooter, LbrxKioskHeader, LbrxButton, LbrxIconSelector },
  props: {
    themeTemplate: { required: true, type: Object },
    services: { required: true, type: Array },
    modelValue: { required: true, type: Boolean },
    popup: { required: true, type: Boolean },
    categories: { required: false, type: Array },
    categoryChosen: { required: false, type: Boolean, default: true },
  },
  emits: ['validate', 'checkService', 'long-press', 'checkCategory', 'cancel'],
  directives: {
    'long-press': longPressDirective,
  },
  data() {
    return {
      allowPagination: false,
      language: 'fr',
      currentPage: 1,
      pagesCount: 0,
      itemsPerPage: 12,
      onLine: navigator.onLine,
      isMobile: false,
    };
  },
  created() {
    if (this.themeTemplate.allowPagination != null) {
      this.allowPagination = this.themeTemplate.allowPagination;
    }
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.itemsPerPage = this.popup ? 2 : Math.floor(window.innerHeight / 180) * 3;
    this.pagesCount = Math.ceil(this.services.length / this.itemsPerPage);
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    getFontSize(label) {
      if (label.length > 100) return "15px";
      if (label.length > 50) return "18px";
      return "22px";
    },
    handleLongPress(service) {
      this.longPress = true;
      this.$emit('long-press', service);
    },
    handleClick(service) {
      this.$emit('checkService', service);
    },
    handleClickCategory(category) {
      this.$emit('checkCategory', category);
    },
    handleTouch(service) {
      if (this.longPress) {
        this.longPress = false;
        return;
      }
      this.$emit('checkService', service);
    },
    setPage(pageNumber) {
      if (this.currentPage !== pageNumber) {
        this.currentPage = pageNumber;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.pagesCount) {
        this.currentPage++;
      }
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  computed: {
    getHeight() {
      if (this.allowPagination) return "76px";
      return (window.innerHeight + 100) / this.services.length <= 76 ? `${(window.innerHeight + 100) / this.services.length}px` : "76px";
    },
    showNextButton() {
      return (+JSON.parse(JSON.parse(window.localStorage.getItem('kiosk')).config).allow_multiple_services || JSON.parse(window.localStorage.getItem('kiosk')).allow_sms_ticket);
    },
    phoneNumberNotAllowed() {
      return !JSON.parse(window.localStorage.getItem('kiosk')).allow_sms_ticket;
    },
    getBackground() {
      if (this.popup) return "#FFFFFF";
      if (this.themeTemplate.services.background.includes('base64')) {
        return `url(${this.themeTemplate.services.background})`;
      }
      return this.themeTemplate.services.background;
    },
    servicesPage() {
      if (this.allowPagination) {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        return this.services.slice(startIndex, startIndex + this.itemsPerPage);
      }
      return this.services;
    },
    categoriesPage() {
      if (this.allowPagination) {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        return this.categories.slice(startIndex, startIndex + this.itemsPerPage);
      }
      return this.categories;
    },
    isDisabled() {
      return this.modelValue;
    },
    getServiceBg() {
      if (this.themeTemplate.services.serviceBackground.includes('base64')) {
        return `url(${this.themeTemplate.services.serviceBackground})`;
      } else if (this.popup && this.themeTemplate.services.background.includes('base64')) {
        return `url(${this.themeTemplate.services.background})`;
      }
      return this.themeTemplate.services.serviceBackground;
    },
    getServiceTextColor() {
      if (this.popup && this.themeTemplate.services.background.includes('base64')) {
        return this.themeTemplate.services.titleColor;
      }
      return this.themeTemplate.services.serviceTextColor;
    },
    getTitleColor() {
      return this.themeTemplate.services.titleColor;
    },
    getCategoryTitle() {
      if (this.$i18n.locale == "fr") return this.themeTemplate.categoryTitleTextFr;
      if (this.$i18n.locale == "ar") return this.themeTemplate.categoryTitleTextAr;
      return this.themeTemplate.categoryTitleTextEn;
    },
  },
};
</script>

<style scoped>

.servicesPaginationsContainer {
  height: 88%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.paginator-holder {
  height: 3vmin;
}

.footer-container {
  width: 100% !important;
  height: auto;
}

.titleContainer {
  color: v-bind(getTitleColor);
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.3vw;
  line-height: 2vmin;
  margin: 5vmin;
}

.buttons-container {
  margin-bottom: 2vh;
  padding-left: 2vmin;
  padding-right: 2vmin;
  width: 100% !important;
}

.mainContainer {
  flex: 1; 
  display: flex;
  flex-direction: column;
  overflow: hidden; 
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; 
}
[class^="col-"] {
  padding: 0 10px; 
}

.fifthThemeContainer {
  background: v-bind(getBackground) no-repeat;
  background-size: cover;
  overflow-x: hidden;
  width: 100%;
  height: 100vh; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.labelContainer {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  color: v-bind(getServiceTextColor);
  text-align: left;
  margin: auto 2vmin;
  padding-bottom: 10px;
  padding-top: 5px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.singleService {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  cursor: pointer;
  height: v-bind(getHeight);
  margin-bottom: 2vmin;
  width: 100%;
  background: v-bind(getServiceBg);
  border-radius: 0px;
}


.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; 
}

[class^="col-"] {
  padding: 0 10px; 
}

.services-container {
  flex: 1; 
  overflow-y: auto; 
  overflow-x: hidden; 
  padding: 10px; 
}
.nextButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2.5vh;
  height: 10vh;
  margin-bottom: 2vmin;
}

@media (max-width: 768px) {

  .title-class {
    font-size: 25px;
}

  .col-12 {
    width: 100%;
  }
.services-container::-webkit-scrollbar {
  width: 12px; 
}

.services-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px; 
}

.services-container::-webkit-scrollbar-thumb {
  background: #f39800;
  border-radius: 10px; 
}

.services-container::-webkit-scrollbar-thumb:hover {
  background: #f39800; 
}
}
</style>