<template>
    <div v-if="times.length" style="display: flex;justify-content: center;width: 100%">
        <div ref="timesBox" :class="{'right-padding': $i18n.locale === 'ar', 'left-padding': $i18n.locale !== 'ar'}" class="times-box">
            <div class="row" v-for="(hour, index) in hours" :key="index">
                <div class="singleTimeSlot" @click="selectTime(time)" :class="{'disabled': !time.active, 'selected': selectedTime===time}" v-for="(time,index) in hour.times" :key="index">
                    {{time.slot}}
                </div>
            </div>
        </div>
    </div>
        <div class="times-box" style="display: flex" v-else-if="modelValue">
            <div class="spinner-border" :style="{'color':getTimeSlotBackground}" style="margin: auto;height: 9vmin;width: 9vmin;font-size: 3vmin" role="status">
            </div>
        </div>
        <div class="times-box" style="display: flex;justify-content: center" v-else>
            <div style="margin: auto">
                {{ $t('Kiosk.noSlots') }}
            </div>
        </div>
</template>
<script>
import colorShader from "@/_helpers/color-shader";

export default {
    name: 'LbrxBookingTimePicker',
    props: {
        times:{required:true,type:Array},
        modelValue:{required:true,type:Boolean},
        themeTemplate: {required: true, type:Object},
    },
    data() {
      return{
          maxSlotsCount:0,
          selectedTime:null,
      }
    },
    watch: {
        modelValue :{
            handler:function () {
              this.maxSlotsCount = 0;
            },
            deep: true
        }
    },
    emits: ['timeSelected'],
    computed: {
        hours() {
            const hours = [];
            let j = 0;
            hours[j] = [];
            hours[j].times = [];
            hours[j].times.push(this.times[j]);
            for(let i=1;i<this.times.length;i++) {
                if(this.times[i].slot.split(":")[0] === hours[j].times[hours[j].times.length-1].slot.split(":")[0]) {
                    hours[j].times.push(this.times[i]);
                    if(this.maxSlotsCount < hours[j].times.length)
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.maxSlotsCount = hours[j].times.length;
                } else {
                    if(this.maxSlotsCount < hours[j].times.length)
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.maxSlotsCount = hours[j].times.length;
                    j++;
                    hours[j] = [];
                    hours[j].times = [];
                    hours[j].times.push(this.times[i]);
                }
            }
            return hours;
        },
        singleSlotsWidth() {
            if(this.$refs.timesBox)
                return (this.$refs.timesBox.offsetWidth/this.maxSlotsCount)-10+'px';
            return 85/this.maxSlotsCount+'%';
        },
        getBackground() {
            return this.themeTemplate.booking.background;
        },
        getTitleColor() {
            return this.themeTemplate.booking.titleColor;
        },
        getCalendarBackground() {
            return this.themeTemplate.booking.calendarBackground;
        },
        getTextColor() {
            return this.themeTemplate.booking.textColor;
        },
        getCalendarSlotBackground() {
            return this.themeTemplate.booking.calendarSlotBackground;
        },
        getTimeSlotBackground() {
            return this.themeTemplate.booking.timeSlotBackground;
        }
    },
    methods: {
        selectTime(time) {
            if(!time.active)
                return;
            if(time === this.selectedTime) {
                this.selectedTime = null;
            } else {
                this.selectedTime = time;
            }
            console.log(time === this.selectedTime);
            this.$emit('timeSelected', this.selectedTime);
        },
    },
}
</script>

<style scoped>
.times-box{
    overflow-y: auto !important;
    height: 97%;
    width: 100%;
    overflow-x: hidden;
}
.right-padding{
    padding-right:1vmin;
    margin-right:2%;
}
.left-padding {
    padding-left:1vmin;
    margin-left:2%;
}
.singleTimeSlot{
    cursor: pointer;
    margin: 0.3em;
    height: 2em;
    width: v-bind(singleSlotsWidth);
    display: flex;
    justify-content: center;
    border: 1px solid v-bind(getTimeSlotBackground);
    color: v-bind(getTimeSlotBackground);
    padding-top: 0.5em;
    font-family: 'Inter',serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 1em;
}
.singleTimeSlot:hover {
    opacity: 0.8;
}
.disabled{
    border: 1px solid #A4A2A1;
    color: #A4A2A1;
    cursor: default;
}
.selected {
    background: v-bind(getTimeSlotBackground);
    color:v-bind(getTextColor)
}
.disabled:hover{
    opacity: 1;
}
@media (max-width: 768px) {

.singleTimeSlot{
    cursor: pointer;
    margin: 0.3em;
    height: 2em;
    width: 9vh;
    display: flex;
    justify-content: center;
    border: 1px solid v-bind(getTimeSlotBackground);
    color: v-bind(getTimeSlotBackground);
    padding-top: 0.5em;
    font-family: 'Inter',serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 1em;
}
.selected {
    background: v-bind(getTimeSlotBackground);
    color:v-bind(getTextColor)
}

}
</style>