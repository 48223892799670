<template>

    <div class="big-container">
    <div class="welcome-container">
        <div>
        <h1 class="welcome-title">
            Borne Interactive
        </h1>
        </div>
        <div class="text-style">
        <div class="welcome-text">
            La solution qui faut pour gérer ses clients en toute tranquillité.
            Conçu pour vos faites gagner du temps et rendre votre
            environnement de travail plus efficace.
        </div>
        </div>
    </div>
    </div>
</template>

<script>

export default {
    name: 'LbrxWelcomeInterface',
    components: {},
}
</script>

<style scoped>



@media (max-width: 700px) {
    .big-container {
        display: none !important;
    }


}

@media (max-width: 1200px) {
    .big-container {
        width:120% !important;
    }
}
.welcome-text {
    font-family: "Exo 2", serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.6vw;
    line-height: 2vw;
    width: 83%;
    margin-top: 4vmin;
    margin-left:  8vmin;
    color: #ffffff;

}
@media (max-width: 768px) {
    .text-style {
        display:flex;
        width: 80% ! important;
    }
}

.text-style {
        display:flex;
        width: 100%
    }
.welcome-title {
    margin: 0 0 0 8vmin;
    font-family: "Exo 2", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 5vw;
    line-height: 5vw;
    /* identical to box height */

    color: #ffffff;
}
.welcome-container {
    width: 100%;
    margin: auto 0;
    display: block;
    overflow: hidden;
    text-align: left;
}
.big-container{
    display:flex;height: 100%;width:60%;
    background: linear-gradient(127.19deg, #008FCA 0.56%, #4BB0DA 76.01%);
}
</style>