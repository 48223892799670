<template>
    <div class="image-container">
        <img class="imageStyle" src="../assets/images/Logo_Bleu.png">
    </div>
</template>

<script>
export default {
    name: "LbrxLoginLogo",
}
</script>
<style scoped>

.imageStyle {
    height: 80%;
}

@media (max-width: 768px) {
    .imageStyle {
    height: 60%;
}
}
@media (max-width: 1200px) {
    .image-container {
        margin-top: 12vmin !important;
    }
}
.image-container {
    margin-top: 10vmin;
    width: 100%;
    height: 14vmin;
    display: flex;
    justify-content: center;
    margin-bottom: 2vmin;
}
</style>