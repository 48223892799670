<template>
    <div v-if="loading" style="height: 100%;display: flex">
        <div class="spinner-border" :style="{'color':themeTemplate.secondaryColor}"
             style="margin: auto;height: 1vh;width: 15vmin;font-size: 6vmin" role="status">
        </div>
    </div>
    <div v-else-if="!data.length" style="height: 100%;display: flex">
        <div style="color:#EB5757;margin: auto;font-size: 1.3vw">
            {{ $t('Kiosk.errorAcquired') }}
        </div>
    </div>
    <div class="termsAndConditions" v-html="data" v-else>
    </div>
</template>
<script>
import {kioskService} from "@/_services";
import DOMPurify from "dompurify";

export default {
    name: "LbrxTermsAndConditions",
    data() {
        return {
            data: '',
            loading:true,
            themeTemplate:null,
        }
    },
    methods: {
      getGlobalTerms() {
        kioskService.getGlobalTerms()
            .then((res)=>{
              console.log(res);
              this.data = DOMPurify.sanitize(res.terms.content);
              this.loading = false;
            })
            .catch((err)=>{
              this.loading = false;
              console.log(err);
            })
      },
      getBusinessTerms() {
        kioskService.getBusinessTerms(JSON.parse(window.localStorage.getItem('business')).id)
            .then((res)=>{
              console.log(res);
              this.data = DOMPurify.sanitize(res.terms.content);
              if(this.data) {
              this.loading = false;
              } else {
                this.getGlobalTerms();
              }
            })
            .catch((err)=>{
              this.getGlobalTerms();
              console.log(err);
            })
      }
    },
    created() {
        this.themeTemplate = JSON.parse(window.localStorage.getItem('theme'));
        if(JSON.parse(window.localStorage.getItem('business')).organization_id != null) {
        kioskService.getTerms(JSON.parse(window.localStorage.getItem('business')).organization_id)
            .then((res)=>{
                this.data = DOMPurify.sanitize(res.terms.content);
                if(this.data) {
                this.loading = false;
                } else {
                  this.getBusinessTerms();
                }
            })
            .catch((err)=>{
              this.getBusinessTerms();
                console.log(err);
            })
        } else {
          this.getBusinessTerms();
        }
    },
}
</script>
<style scoped>
.termsAndConditions{
    padding-right: 5vmin;
    padding-left:5vmin;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: justify;
    color: #737070;
}
.section{
    margin-top: 2vmin;
}
</style>