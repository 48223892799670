function server() {
    if (
        process.env.VUE_APP_EDIT_HOST === "true" &&
        localStorage.getItem("host") != null
    ) {
        return localStorage.getItem("host");
    } else {
        switch (localStorage.getItem("server")) {
            case "tn":
                return process.env.VUE_APP_API_URL_TN;
            case "ci":
                return process.env.VUE_APP_API_URL_CI;
            default:
                return process.env.VUE_APP_API_URL_TN;
        }
    }
    // return "/v1"
}

export const ApiConfigs = {
    base_url: server() + "/kiosk",
    base_url_user: server() + "/user",
    // base_url_business: server() + "/business",
    broadcast_url: server() + "/broadcasting",
    /* -------------------------------- */
    // PUSHER
    pusher: {
        auth: "/auth",
    },
    //DISPLAY LOGIN
    kiosk: {
        login: "/auth",
        logout: "/logout",
        refresh: "/refresh",
        push: "/queue/push",
        search: "/customer/search",
        create_customer: "/customer/create",
        autoLogin: "/auth/:key/:token",
        ping: "/ping",
        terms: '/terms/:id',
        businessTerms: '/business/terms/:id',
        policy: '/policy/:id',
        businessPolicy: '/business/policy/:id',
        generateAuthCode: "/auth/generate",
        confirmAuthByCode: "/auth/confirm"
    },
    booking: {
        getAvailableSlots: "/booking/availability",
        create: "/booking/create",
    },
    /* -------------------------------- */
    // CUSTOMERS
    customers: {
        getAll: "/customer?pagination=1000",
        get: "/customer/:id",
        pagination: "/customer?page=",
        filter: "/customer/filter/",
        create: "/customer/create",
        update: "/customer/:id/update",
        _delete: "/customer/:id/delete",
    },
    /* -------------------------------- */

    // QUEUES
    queues: {
        get: "/queue/:id",
        getForDispay: "/queue/:id",
        getInServiceForDisplay: "/queue/:id/inservice",
        getWaitingListForDisplay: "/queue/:id/waitinglist",
        getInServiceTickets: "/queues/inServiceTickets",
        voiceTelling: "/voice",
        getWaitingList: "/queue/:id/waitinglist",
    },

    categories: {
        getCategories: "/services-categories"
    }
};
