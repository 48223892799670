<template>
  <div>
    <div class="login-container">
      <lbrx-welcome-interface></lbrx-welcome-interface>
      <div class="form-footer-container">
        <lbrx-login-logo></lbrx-login-logo>
        <div class="login-form-container">
          <div style="width: 90%;padding-top: 2vmin">
            <div v-if="errorLogin" class="error-container">
              <lbrx-error-notification :label="errors[errors.length-1]" color="#FF5757"
                                       background="rgba(255, 158, 152, 0.5)" height="10" width="96%"
                                       font-size="0.7" icon="error-icon"></lbrx-error-notification>
            </div>
            <div class="form-container" v-if="!codeSet && !authenticated && codeChances !== 0">
              <div class="auth-title">
                {{ $t('Toasts.code') }}
              </div>
              <div class="auth-code">
                {{ authCode }}
              </div>
              <LbrxCountdown color="#008FCA" v-model="startCounter" @counter-ended="counterEnded"
                             :timer="timer"></LbrxCountdown>
            </div>
            <div class="form-container" style="margin-top: 2vmin" v-else-if="authenticated">
              <div class="auth-title" v-if="!loggingOut">
                {{ $t('Toasts.text_1') }}
              </div>
                <div class="auth-title" v-else>
                    {{ $t('Toasts.text_logout') }}
                </div>
                <div style="color: #008FCA;margin-top: 1.5vmin" class="auth-code auth-second-code">
                {{ business_name}}
              </div>
              <div style="color: #8A8A8A;margin-top: 1.5vmin;margin-bottom: 3vmin" class="auth-sub-title">
                {{ kiosk_name}}
              </div>
              <lbrx-button class="margin-bottom" v-model="sending" icon="confirm-icon"
                           @clicked="SocketLogin()"  :label="$t('Toasts.AcceptButton')" color="#FFFFFF"
                           background="#008FCA" height="10vmin" width="52%" font-size="1.1"></lbrx-button>
              <lbrx-button v-model="sending" icon="cancel-red-icon"
                           @clicked="cancelLogin"
                           :label="!loggingOut ? $t('Toasts.CancelButton') : $t('Toasts.CancelLogoutButton')" color="#FF5757" background="white" height="10vmin" width="52%"
                           font-size="1.1" border="1px solid #FF5757"></lbrx-button>
            </div>
            <div class="form-container" v-if="codeChances === 0 && !authenticated">
              <lbrx-button class="margin-bottom" color="#FFFFFF"
                           @clicked="refreshPage"
                           label="Refresh"
                           background="#008FCA" height="10vmin" width="60%" font-size="1.1">
              </lbrx-button>
            </div>
          </div>
        </div>
        <div class="login-footer">
          <div class="footer-container">
            <div class="copyright">
              Liberrex.com © Copyright {{ new Date().getFullYear() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LbrxErrorNotification from "@/components/LbrxErrorNotification.vue";
import {mapActions} from "vuex";
import LbrxWelcomeInterface from "@/components/LbrxWelcomeInterface.vue";
import LbrxLoginLogo from "@/components/LbrxLoginLogo.vue";
import LbrxCountdown from "@/components/LbrxCountdown.vue";
import LbrxButton from "@/components/LbrxButton.vue";
import {kioskService} from "@/_services/kiosk.service";
import router from "@/router";

export default {
  name: "LoginPage",
  inject: ['echo'],
  components: {
    LbrxButton,
    LbrxCountdown,
    LbrxLoginLogo,
    LbrxWelcomeInterface,
    LbrxErrorNotification
  },
  data() {
    return {
      //show login if not authenticated else logout
      authenticated: false,
      //while not authenticated :show counter if code is not set else show confirm login
      codeSet: false,
      codeChances: 2,
      loggingOut:false,
      //set true to start counter
      startCounter: false,
      //counter value
      timer: 60,
      //other values
      authCode: "***-***",
      business_name: '',
      kiosk_name: '',
      errorLogin: false,
      sending: false,
      email: '',
      password: '',
      rememberMe: false,
      login_data: null,
      errors: [],
    }
  },
  watch: {},
  mounted() {
      if(window.localStorage.getItem("loggingOut"))
      {
          this.authenticated = true;
          this.codeSet = true;
          this.loggingOut = true;
          this.business_name = JSON.parse(window.localStorage.getItem("business")).name;
          this.kiosk_name = '"'+JSON.parse(window.localStorage.getItem("kiosk")).name+'"';
          window.localStorage.removeItem("loggingOut")
      }
      this.generateAuthCode()
      this.startCounter = true
  },
  methods: {
    refreshPage() {
        location.reload();
    },
    cancelLogin() {
        if(this.loggingOut) {
         // window.localStorage.setItem("token", window.localStorage.getItem("archiveToken"));
         // window.localStorage.removeItem("archiveToken");
          window.localStorage.removeItem("loggingOut");
          router.push({name: "waitingQueue"});
        } else {
            this.codeSet = false;
            this.authenticated = false;
            this.codeChances = 2;
        }
    },
    counterEnded() {
      this.startCounter = false
      this.codeChances -= 1
      if (this.codeChances > 0) {
        this.generateAuthCode()
        setTimeout(() => {
          this.startCounter = true
        }, 1)
      }
    },
    ...mapActions("authentication", ["login"]),
    validEmail(email) {
      let re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm() {
      this.errors = [];

      if (!this.email.length) {
        this.errors.push("email required.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Valid email required.");
      }
      if (!this.password.length) {
        this.errors.push("password required.");
      }
      if (!this.errors.length) {
        return true;
      }
      this.errorLogin = true;
      this.sending = false;
    },
    handleSubmit() {
      this.errorLogin = false;
      this.sending = true;
      if (this.checkForm()) {
        this.errorLogin = false;
        const {email, password} = this;
        this.login({email, password})
            .then(() => {
              this.sending = false;
            })
            .catch(() => {
              this.sending = false;
              this.errors.push("Email ou mot de passe incorrecte");
              this.errorLogin = true;
            });
      }
    },
    SocketLogin() {
      if(this.loggingOut) {
        localStorage.clear();
        location.reload();
      } else {
        // localStorage.setItem('token', this.login_data.token)
        let payload = {
          kiosk_id:this.login_data.kiosk.id,
          auth_token: this.login_data.token
        }

        kioskService.confirmAuthByCode(payload).then((res)=>{
          localStorage.setItem('kiosk', JSON.stringify(this.login_data.kiosk))
          localStorage.setItem('business', JSON.stringify(this.login_data.business))
          location.reload();
        })

      }
    },
    generateAuthCode() {
        kioskService
          .generateAuthCode()
          .then(
              function (res) {
                this.authCode = res.code.toString()
                    .replace(/(\d{3})(\d{3})/, "$1-$2");
                this.echo.channel("kiosk-auth-" + res.code).listen(
                    ".kiosk-auth",
                    function (socket_data) {
                      this.$i18n.locale =JSON.parse(socket_data.kiosk.config).default_language;
                      localStorage.setItem('Language', JSON.parse(socket_data.kiosk.config).default_language);
                      this.codeChances = 0
                      this.authenticated = true
                      this.business_name = JSON.stringify(socket_data.business.name)
                      this.kiosk_name = JSON.stringify(socket_data.kiosk.name)
                      this.login_data = socket_data
                    }.bind(this)
                )
              }.bind(this)
          ).catch(
          function (err) {
            console.log(err)
          }.bind(this)
      );
    },
  }
}
</script>

<style scoped>
@media (max-width: 1200px) {
    .form-footer-container {
        justify-content: center !important;
        width: 100% !important;
    }

    .margin-bottom {
        margin-bottom: 2vmin !important;
    }
}

.error-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2vmin;
}

.footer-container {
    text-align: center;
    width: 50%;
}

.login-footer {
    width: 100%;
    display: flex;
    margin-bottom: 1vmin;
    justify-content: center;
}

.login-form-container {

    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin: auto;
    padding-bottom: 13vh !important;
}

.login-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: auto !important;
}

.form-footer-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: space-between;
}

.auth-title {
    font-family: 'Exo 2', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 26px;

    /* Medium Gray */

    color: #8A8A8A;
}

.auth-second-code {
    font-size: 22px !important;
    line-height: 6vmin !important;
    font-weight: 400 !important;
}

.auth-code {
    font-family: 'Exo 2', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 8vw;
    line-height: 14vmin;
    margin-top: 1.5vmin;

    color: #8A8A8A;
}

.auth-sub-title {
    font-family: 'Exo 2', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 3.1vmin;
    line-height: 3.8vmin;

    /* Primary */
    margin-bottom: 1.6vmin;
}
</style>
