<template>
    <div id="ticketPopup" v-if="modelValue" class="popup-container">
        <Transition name="fade">
            <div v-if="showPopup" class="popup-box">
                    <div class="popup-header" :style="{ 'border-bottom': ticket ? '2px dashed '+primaryColor : ''}">
                        <div class="close-popup-container">
                            <div @click="closeThis()" class="close-popup">
                                X
                            </div>
                        </div>
                        <div v-if="ticket" class="ticket-number">
                            {{ticket.public_identifier}}
                        </div>
                        <div v-if="ticket" class="ticket-title">
                            {{$t('Kiosk.ticketNumber')}}
                        </div>
                        <div v-else class="ticket-title">
                            {{ $t('Kiosk.appointmentCreated') }}
                        </div>
                    </div>
                    <div v-if="ticket" class="popup-body">
                        <div class="business-and-date">
                        <div class="business-name">
                            {{businessName}}
                        </div>
                        <div class="ticket-date">
                            {{getCheckInDate}} {{ticket.checkinTime}}
                        </div>
                        </div>
                        <div class="operations">
                            <div class="operations-title">
                                {{ $t('Kiosk.ticketOperations') }}
                            </div>
                            <div class="services">
                                <div :style="{'direction': $i18n.locale === 'ar' ? 'rtl': 'ltr'}" v-for="(service, index) in ticket.services" :key="index" class="single-service">
                                    <span v-if="service.translations.length">{{JSON.parse(service.translations)[$i18n.locale]}}</span>
                                    <span v-else>{{service.title}}</span>
                                    <span style="margin-left: 2px;margin-right: 2px">(x{{service.qte}})</span>
                                </div>
                            </div>
                        </div>

                        <div class="qrcodeContainer">
                            <div class="qrcode">
                                <vue-qrcode :value="ticket.short_link" :options="{ width: 130}"></vue-qrcode>
                            </div>
                            <div class="qrcode-title">
                                {{ $t('Kiosk.qrCodeTitle') }}
                            </div>
                        </div>
                    </div>
                    <div v-else class="popup-body">
                        <div class="business-and-date">
                        <div class="business-name">
                            {{businessName}}
                        </div>
                        <div class="ticket-date" style="margin-bottom: 5vmin;font-size: 18px;margin-top: 20px">
                            {{getCheckInDate}} {{$t('Kiosk.appointmentSubTitle')}} {{getCheckIntime}}
                        </div>
                        </div>
                    </div>
            </div>
        </Transition>
    </div>
</template>

<script>

import colorShader from "@/_helpers/color-shader";
import moment from "moment";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import router from "@/router";

export default {
    name: 'LbrxKioskTicketPopup',
    components: {
        VueQrcode
    },
    props: {
        modelValue:Boolean,
        ticket: {required:false,type:Object},
        booking: {required:false,type:Object},
        primaryColor: {required:true,type:String},
        secondaryColor: {required:true,type:String},
        textColor: {required:true,type:String},
    },
    emits: ["resetAll"],
    data() {
        return {
            businessName:"",
            showPopup:false,
            loading:false,
            closeTimeout: null,
            mainTheme:null,
        }
    },

    computed: {
        ticketFontSize() {
            if(this.ticket) {
                if(this.ticket.public_identifier.length < 5)
                    return "6.5vw";
                return 42/this.ticket.public_identifier.length+"vw";
            }
            return "0vw";
        },
        getCheckInDate() {
            if(this.ticket)
                // eslint-disable-next-line vue/no-mutating-props,vue/no-side-effects-in-computed-properties
                return this.ticket.checkinDate = moment().format('DD/MM/YYYY');
            // eslint-disable-next-line vue/no-mutating-props,vue/no-side-effects-in-computed-properties
            return moment(this.booking.start_datetime).format('DD/MM/YYYY');
        },
        getCheckIntime() {
            // eslint-disable-next-line vue/no-mutating-props,vue/no-side-effects-in-computed-properties
            return moment(this.booking.start_datetime).format('HH:ss');
        },
    },
    methods: {
        checkService(service) {
            // eslint-disable-next-line vue/no-mutating-props
            this.service.child[this.service.child.indexOf(service)].checked = !this.service.child[this.service.child.indexOf(service)].checked;
        },
        validateServices() {
            this.closeThis();
        },
        closeThis() {
            clearTimeout(this.closeTimeout);
            const ln = JSON.parse(JSON.parse(window.localStorage.getItem('kiosk')).config).default_language;
            // window.localStorage.setItem('Language', ln)
            if(router.currentRoute.value.name !== 'waitingQueue')
                router.push({name:'waitingQueue'});
            else {
                this.$emit("resetAll");
            }

            // this.showPopup = false;
            // setTimeout(()=>{
            //     this.$emit('close');
            // },700)
        },
    },
    created() {
        this.mainTheme = JSON.parse(window.localStorage.getItem('theme'));
        this.businessName = JSON.parse(window.localStorage.getItem("business")).name;
        document.addEventListener('keyup',(event)=>{
            event.key === 'Escape' ? this.closeThis():null;
        });
        this.loading = false;
        setTimeout(()=>{
            this.showPopup = true;
        },1)

       this.closeTimeout = setTimeout(()=>{
            this.closeThis();
        },this.mainTheme.printTicket.printTicketTimeout);

        this.backgroundTicketHeader = this.mainTheme.printTicket.backgroundTicketHeader ? this.mainTheme.printTicket.backgroundTicketHeader : '#E9E4E1';
        this.ticketHeaderTitle = this.mainTheme.printTicket.ticketHeaderTitle ? this.mainTheme.printTicket.ticketHeaderTitle : '#B3B0AD';
    },
}
</script>

<style scoped>


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.popup-container{
    background: rgba(0,0,0,0.7);
    display: flex !important;
    justify-content: center !important;
    overflow-y: auto !important;
    padding-top: 2vmin;
    padding-bottom: 2vmin;
}
@media (max-aspect-ratio: 1/1) {
    .popup-box{
        width: 68vw !important;
    }

}

.popup-box {
    margin: auto;
    overflow-y: auto !important;
    width: 40vw;
    height: auto;
    background: #E9E4E1 !important;
    display: flex;
    flex-direction: column;
}
.popup-body {
    overflow-y: auto !important;
    padding-top: 5vmin;
    height: 100% !important;
    margin:0 !important;
    background: v-bind(textColor);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.popup-header{
    height: 40% !important;

}
.ticket-number{
    margin-top: -3vmin;
    width: 100%;
    display: flex;
    justify-content: center;

    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 600;
    font-size:v-bind(ticketFontSize);
    line-height: v-bind(ticketFontSize);
    text-align: center;
    text-transform: uppercase;

    /* Blue Biat */

    color: v-bind(primaryColor);
}
.ticket-title{
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.8vmin;
    line-height: 4vmin;
    text-align: center;
    text-transform: uppercase;

    color: #B3B0AD;
}
.close-popup{
    background: #E9E4E1;
    height: 7vmin;
    width: 7vmin;
    font-weight: 700;
    color: v-bind(primaryColor);
    padding-top: 1vmin;
    font-size: 4vmin;
    line-height: 5vmin;
    float: right;
}

.business-name{
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.8vmin;
    line-height: 3vmin;

    /* Gray 2 */

    color: #4F4F4F;
}

.ticket-date{
    margin-top: 0.5vmin;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.7vmin;
    line-height: 2vmin;

    /* Gray 4 */

    color: #BDBDBD;
}
.operations {
    margin-top: 5vmin;
}
.operations-title {
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 3vmin;
    line-height: 3vmin;


    color: v-bind(primaryColor);

}
.services{
    margin-top: 1vmin;
    margin-bottom: 4vmin;
}
.single-service {
    margin-bottom: 0.2vmin;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.5vmin;
    line-height: 3vmin;
    text-align: center;

    /* Orange Biat */

    color: v-bind(secondaryColor);
}

.qrcodeContainer{

}

.qrcode-title{
    padding-left: 13vmin;
    padding-right: 13vmin;
    margin-bottom: 3vmin;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    text-align: center;

    /* Medium Gray */

    color: #8A8A8A;

}
</style>